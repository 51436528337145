<template>
  <div class="card">
    <div class="card-body">
      <el-row class="text-center mb-8">
        <el-col :xs="24" :md="12">
          <h3 :class="`text-${color}`" class="revenue">
            € {{ exitValuation.toLocaleString() }}
          </h3>
          <span class="subheading">EXIT VALUATION</span>
        </el-col>
        <el-col :xs="24" :md="12">
          <h3 :class="`text-${color}`" class="revenue">
            {{ roi.toLocaleString() }}%
          </h3>
          <span class="subheading">ROI</span>
        </el-col>
      </el-row>
      <div class="d-flex justify-content-between">
        <span class="subheading">REVENUES NOW</span>
        <h5 class="fw-bold">€ {{ currentRevenue.toLocaleString() }}</h5>
      </div>
      <div class="d-flex justify-content-between">
        <span class="subheading">REVENUE {{ get10YearsAhead() }}</span>
        <h5 class="fw-bold">€ {{ predictedRevenue.toLocaleString() }}</h5>
      </div>
      <div class="d-flex justify-content-between">
        <span class="subheading">CAGR</span>
        <h5 class="fw-bold">
          {{ Number(compoundAnnualGrowthRate).toLocaleString() }}%
        </h5>
      </div>
      <div class="d-flex justify-content-between">
        <span class="subheading">EXIT MULTIPLE</span>
        <h5 class="fw-bold">{{ exitMultiple }}X</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { useNumberFormatter } from "../composables/useNumberFormatter";
import moment from "moment";

export default {
  name: "RevenueCard",
  props: {
    color: {
      type: String,
      required: true,
    },
    currentRevenue: {
      type: Number,
      required: true,
    },
    predictedRevenue: {
      type: Number,
      required: true,
    },
    compoundAnnualGrowthRate: {
      type: Number,
      required: true,
    },
    exitMultiple: {
      type: Number,
      required: true,
    },
    exitValuation: {
      type: Number,
      required: true,
    },
    roi: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { formatNumber } = useNumberFormatter();
    const get10YearsAhead = () => {
      return moment().add(10, "years").format("yyyy");
    };
    return { formatNumber, get10YearsAhead };
  },
};
</script>

<style scoped></style>
