<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div
      :class="[
        'modal-dialog modal-dialog-centered',
        showExitEvaluationRevenueCard ? 'mw-1000px' : 'mw-800px',
      ]"
    >
      <div class="modal-content">
        <div
          class="modal-header bg-light-grey"
          id="kt_modal_create_api_key_header"
        >
          <h2>{{ modalTitle }}</h2>
        </div>
        <div class="modal-body bg-light-grey scroll-y py-0">
          <el-row :gutter="20" class="d-flex justify-content-evenly">
            <el-col
              v-for="(forecastObject, index) in forecastConfigurationValues"
              :key="index"
              :span="showExitEvaluationRevenueCard ? 12 : 24"
            >
              <ForecastSliderCard
                v-if="forecastObject.show"
                v-model:value="forecastConfigurationUpdated[index]"
                :forecast-object="forecastObject"
                :scenarios="scenarios"
                :change-scenario="showExitEvaluationRevenueCard"
              />
            </el-col>
          </el-row>
        </div>
        <div class="modal-footer flex-center bg-light-grey">
          <button
            type="reset"
            class="btn me-3 btn-primary"
            @click="saveConfiguration"
            data-bs-dismiss="modal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import ForecastSliderCard from "@/artists/components/ForecastSliderCard";

export default {
  name: "ConfigureForecastModal",
  components: { ForecastSliderCard },
  emits: ["forecastConfig"],
  props: {
    forecastValuesConfigured: {
      type: Boolean,
      default: false,
    },
    forecastConfigurationValues: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    scenarios: {
      type: Array,
      required: true,
    },
    showExitEvaluationRevenueCard: {
      type: Boolean,
      default: true,
    },
    modalTitle: {
      type: String,
      default: "Configure Forecast",
    },
  },
  setup(props, context) {
    const forecastConfigurationUpdated = ref(props.forecastConfigurationValues);

    const saveConfiguration = () => {
      context.emit("forecastConfig", {
        scenarios: props.scenarios,
        forecastConfig: forecastConfigurationUpdated.value,
      });
    };

    return {
      forecastConfigurationUpdated,
      saveConfiguration,
    };
  },
};
</script>

<style scoped>
.bg-light-grey {
  background-color: #f4f7f9;
}
</style>
