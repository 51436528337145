<template>
  <div :class="[chartClass ? ' revenue-chart' : '']">
    <apexchart
      :type="chartType"
      :ref="chartRef"
      :height="chartHeight"
      :series="series"
      :key="JSON.stringify(chartOptions)"
      :options="chartOptions"
      @mounted="chartOptions.chart.events.mounted"
      @updated="chartOptions.chart.events.updated"
    ></apexchart>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";
import $ from "jquery";

export default {
  name: "RevenueChart",
  props: {
    chartClass: {
      type: Boolean,
      default: false,
    },
    chartType: {
      type: String,
      default: "line",
    },
    chartHeight: {
      type: Number,
      default: 350,
    },
    series: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => ["#009EF7"],
    },
    colorDefault: {
      type: Boolean,
      default: false,
    },
    currencyValues: {
      type: Boolean,
      default: true,
    },
    deselectSeries: {
      type: Boolean,
      default: false,
    },
    strokeWidth: {
      type: Array,
      default: () => [],
    },
    plotOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dataLabels: {
      type: Object,
      default: () => {
        return {
          enabled: false,
        };
      },
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    hitEvents: {
      type: Boolean,
      default: false,
    },
    yAxisShow: {
      type: Boolean,
      default: true,
    },
    labelsRotate: {
      type: Boolean,
      default: true,
    },
    fill: {
      type: Object,
      default: () => {
        return {
          opacity: 1,
        };
      },
    },
    showXAxisLabels: {
      type: Boolean,
      default: false,
    },
    tooltipYaxis: {
      type: Array,
      default: () => [
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const chartRef = ref(null);
    const chartOptions = {
      chart: {
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        stacked: props.stacked,
        events: {
          mounted: () => {
            addClasses();
          },
          updated: () => {
            addClasses();
          },
        },
      },
      // colors: props.colors,
      dataLabels: props.dataLabels,
      fill: props.fill,
      plotOptions: props.plotOptions,
      stroke: {
        colors: props.chartType === "bar" ? ["transparent"] : props.colors,
        width: props.strokeWidth.length
          ? props.strokeWidth
          : Array(props.categories.length).fill(5),
        curve: "smooth",
        show: true,
      },
      legend: {
        show: true,
        fontSize: "12px",
        fontWeight: 400,
        labels: {
          colors: labelColor,
        },
        markers: {
          radius: 12,
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: props.categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: props.showXAxisLabels,
          rotate: -45,
          rotateAlways: props.labelsRotate,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: 30,
          maxHeight: 500,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        show: props.yAxisShow,
        labels: {
          formatter: (value) => {
            if (props.currencyValues) {
              return "€ " + formatNumberIntoHumanizeForm(value);
            }
            return formatNumberIntoHumanizeForm(value);
          },
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: props.tooltipYaxis,
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: props.yAxisShow,
          },
        },
      },
    };

    watch(
      () => props.colorDefault,
      (value) => {
        if (!value) {
          chartOptions["colors"] = props.colors;
        }
      },
      { deep: true, immediate: true }
    );

    const addClasses = () => {
      if (props.hitEvents) {
        $(document).ready(function () {
          $(".apexcharts-series").each(function () {
            const refElement = $(this).attr("seriesName");
            if (
              refElement === "UpsidexBudgets" ||
              refElement === "MarketingxForecast"
            ) {
              const id = $(this).attr("id");
              $("#" + id).addClass("round-single-bar-revenue-chart");
            }
          });
        });
      }
    };
    return { chartOptions, chartRef };
  },
};
</script>

<style lang="scss">
.revenue-chart {
  .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
    clip-path: inset(0% 0% -100% 0% round 10px);
  }
}

.round-single-bar-revenue-chart path {
  clip-path: inset(0% 0% -100% 0% round 10px);
}
</style>
