<template>
  <div>
    <el-slider
      :min="min"
      :max="max"
      :step="step"
      :model-value="value"
      @input="updateValue"
    ></el-slider>
    <span>{{ sliderDescription }}</span>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    sliderDescription: {
      type: String,
      required: false,
      default: null,
    },
    min: {
      type: Number,
      required: false,
      default: 1,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:value"],
  setup(props, context) {
    const updateValue = (value) => {
      context.emit("update:value", value);
    };
    return { updateValue };
  },
};
</script>

<style scoped></style>
