<template>
  <div class="forecast-slider-card">
    <div class="card mb-7">
      <div class="card-body">
        <h4 class="heading">{{ forecastObjectChild.title }}</h4>
        <div
          v-for="scenario in scenarios"
          :key="scenario"
          class="d-flex justify-content-between align-items-center py-1"
        >
          <span class="w-50 fw-bold">
            {{
              changeScenario
                ? scenario.toUpperCase()
                : changeScenarioName(scenario)
            }}</span
          >
          <div class="w-75 d-flex align-items-center">
            <span class="slider-value">{{
              forecastObjectChild[scenario.replace(/\s/g, "")]
            }}</span>
            <Slider
              class="w-100"
              @update="$emit('update:value', value)"
              v-model:value="forecastObjectChild[scenario.replace(/\s/g, '')]"
              :value="forecastObjectChild[scenario.replace(/\s/g, '')]"
              :min="forecastObject.min"
              :max="forecastObject.max"
              :step="forecastObject.step ? forecastObject.step : 1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@/artists/components/Slider";
import { ref, watch } from "vue";

export default {
  name: "ForecastSliderCard",
  components: {
    Slider,
  },
  props: {
    forecastObject: {
      type: Object,
      required: true,
    },
    scenarios: {
      type: Array,
      required: true,
    },
    changeScenario: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    let forecastObjectChild = ref(props.forecastObject);
    const changeScenarioName = (scenario) => {
      if (scenario === "positive") {
        return "Assumed YOY Growth Rate";
      } else if (scenario === "medium") {
        return "Market Growth";
      } else if (scenario === "negative") {
        return "50% of Market Growth";
      }
    };
    watch(
      () => props.forecastObject,
      (value) => {
        forecastObjectChild.value = value;
      },
      { deep: true }
    );
    return {
      forecastObjectChild,
      changeScenarioName,
    };
  },
};
</script>

<style scoped>
.slider-value {
  margin-right: 1.5rem;
}
</style>
